export class ObtainTokenError extends Error {
  sub: string;

  constructor(msg: string, sub: string) {
    super(msg);
    this.sub = sub;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ObtainTokenError.prototype);
  }
}
