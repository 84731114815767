import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useSPARouter } from '@didomi/utility-react';
import { useRegisterUser, useLogin } from '../../hooks';
import { RegisterWithPartnerCard } from '../../components/RegisterWithPartnerCard/RegisterWithPartnerCard';
import { RegisterUserExistsCard } from '../../components/RegisterUserExistsCard/RegisterUserExistsCard';
import { RegisterCardLoading } from '../../components/RegisterCardLoading/RegisterCardLoading';
import { RegisterCardEmailPassword } from '../../components/RegisterCardEmailPassword/RegisterCardEmailPassword';
import { RegisterCardError } from '../../components/RegisterCardError/RegisterCardError';

export const Register = () => {
  const [registerWithPartner, setRegisterWithPartner] = useState<string | null>(
    null,
  );
  const [registerUserData, setRegisterUserData] = useState(null);

  const {
    mutate: login,
    isLoading: loadingLoginUser,
    error: errorInLogin,
  } = useLogin();

  const { navigateTo } = useSPARouter();

  useEffect(() => {
    // TODO: Move this logic to the GTM utility and a react version
    window['dataLayer']?.push({
      event: 'product-version',
      product: 'self-service',
      page_path: 'register',
    });
  }, []);

  const {
    mutate: registerUser,
    reset: resetRegisterUser,
    isLoading: registeringUser,
    error: errorRegisteringUser,
  } = useRegisterUser({
    onSuccess: (_, reg) => {
      setRegisterUserData(null);
      login({
        email: reg.email,
        password: reg.password,
        refreshOrgs: true, // Tell login method to take care of feature flags and organizations
      }).then(() => navigateTo('/'));
    },
  });

  const startRegistration = async (newUser) => {
    setRegisterUserData(newUser);
    registerUser(newUser);
  };

  const resetPage = () => {
    resetRegisterUser();
    setRegisterUserData(null);
  };

  if (!!registerWithPartner) {
    return (
      <RegisterWithPartnerCard
        partner={registerWithPartner}
        onGoBack={() => setRegisterWithPartner(null)}
      />
    );
  }

  const errorMsg = errorRegisteringUser?.response?.data?.message;
  if (
    errorMsg === 'The user already exists.' ||
    errorMsg?.startsWith('User has active account |') ||
    errorInLogin
  ) {
    let activeIdentityProvider = null;
    if (errorMsg?.startsWith('User has active account |')) {
      activeIdentityProvider = errorMsg.split('|')[1];
    }

    return (
      <RegisterUserExistsCard
        onGoBack={resetPage}
        activeIdentityProvider={activeIdentityProvider}
      />
    );
  }

  if (registeringUser || loadingLoginUser) {
    return <RegisterCardLoading />;
  }

  if (errorRegisteringUser) {
    return <RegisterCardError onRetry={() => registerUser(registerUserData)} />;
  }

  return (
    <RegisterCardEmailPassword
      onRegisterWithSocialSSO={(p) => setRegisterWithPartner(p)}
      onRegistrationStart={(u) => startRegistration(u)}
    />
  );
};
