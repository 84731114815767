import { useEffect, useRef, useState } from 'preact/hooks';
import sanitizeHtml from 'sanitize-html';
import { useHash } from './useHash.hook';
import { useLocationSearch } from './useLocationSearch.hook';

/**
 * This hook is used to extract an error either from hash params
 * or from search in the url.
 *
 * Hash params error appears when post login action fails,
 * and the search string comes into play when a generic error
 * from auth0 is returned
 *
 * @returns { Auth0Error } auth0 extracted error
 */
export const useAuth0ExtractUrlError = () => {
  const [error, setError] = useState(null);
  const componentIsMounted = useRef(true);

  const [hash] = useHash();
  const search = useLocationSearch();

  const isSocialSignupState = (key: string, state: any) => {
    if (key !== 'state') {
      return false;
    }

    try {
      const parsedState = JSON.parse(state);
      const expectedKeys = [
        'terms_version',
        'privacy_policy_version',
        'xsrf_protection',
      ];
      return (
        parsedState &&
        expectedKeys.every((key) => parsedState[key] !== undefined)
      );
    } /* istanbul ignore next */ catch {
      return false;
    }
  };

  useEffect(() => {
    const hashLocation = hash.replace('#', '?');
    const searchLocation = search;

    const urlParams = new URLSearchParams(hashLocation || searchLocation);
    const errorFields = ['error', 'error_description', 'tracking', 'state'];

    const pathParamError = errorFields.reduce((result, key) => {
      const value = urlParams.get(key);
      if (value !== null && !isSocialSignupState(key, value)) {
        result[key] = sanitizeHtml(value);
      }
      return result;
    }, {});

    if (componentIsMounted && Object.keys(pathParamError).length) {
      setError(pathParamError);
    }

    return () => {
      componentIsMounted.current = false;
    };
  }, [hash, search]);

  return error;
};
