import { h } from 'preact';
import Router from 'preact-router';

import { Register } from '../Register/Register';
import { CMSLayout } from '../../layouts/CMSLayout/CMSLayout';

import { QueryClient, QueryClientProvider } from 'react-query';

import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';

import { connect, theme } from '@didomi/ui-foundation';
import { Auth0Callback } from '../Auth0Callback/Auth0Callback';
import { RedirectToAuth0 } from '../../components/RedirectToAuth0/RedirectToAuth0';
import { Auth0TestPage } from '../Auth0TestPage/Auth0TestPage';

connect('.auth-root', {
  theme: {
    extend: {
      ...theme.extend,
      backgroundImage: {
        ...theme.extend.backgroundImage,
        register:
          'linear-gradient(151.09deg, #FAFBFF 11.85%, #FAFBFF 52.03%, rgba(253, 232, 238, 0.7) 85.58%, #D4E5F9 102%)',
      },
      colors: {
        ...theme.extend.colors,
        'electric-blue': '#2E62D6',
      },
    },
  },
});

const queryClient = new QueryClient();

export const App = () => {
  return (
    <UtilityProvider utility={utility}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <CMSLayout path="/auth/register">
            <Register />
          </CMSLayout>
          <Auth0Callback path="/auth/callback" />
          <Auth0TestPage path="/auth/test" />
          <RedirectToAuth0 default />
        </Router>
      </QueryClientProvider>
    </UtilityProvider>
  );
};
