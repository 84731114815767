import { useState } from 'preact/hooks';
import { useAuthMethods } from '@didomi/utility-react';

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(undefined);

  const { login } = useAuthMethods();

  const mutate = async ({ email, password, refreshOrgs }, options?) => {
    setIsLoading(true);
    try {
      const result = await login(email, password, refreshOrgs);

      if (options?.onSuccess) {
        options.onSuccess(result);
      }
      setIsLoading(false);
    } catch (error) {
      if (options?.onError) {
        options.onError(error);
      }
      setIsLoading(false);
      setLoginError(error);
    }
  };

  return { mutate, isLoading, error: loginError };
};
