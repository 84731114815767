import * as preact from 'preact';
import singleSpaPreact from 'single-spa-preact';
import { setUpDidomiUIAtoms } from '@didomi/ui-atoms-react';
import { App } from './pages/App/App';

// Entry point for Single SPA
const lifecycles = singleSpaPreact({
  preact,
  rootComponent: App,
});

const { bootstrap, mount: mountOriginal, unmount } = lifecycles;

const setStylesForStandalone = () => {
  document.body.style.height = '100vh';
  document.body.style.margin = '0';
  setTimeout(() => {
    const spaDiv = document.getElementById(
      'single-spa-application:@didomi-spa/auth',
    );
    spaDiv.style.height = '100%';
  }, 0);
};

async function mount(opts, props) {
  if (CONFIG.standalone) {
    setStylesForStandalone();
  }
  await setUpDidomiUIAtoms('auth');
  mountOriginal(opts, props);
}

export { bootstrap, mount, unmount };
