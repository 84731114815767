import { h, Fragment } from 'preact';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { useAuth0QueryProfile } from '../../hooks/useAuth0QueryProfile';
import { Error } from '../Error/Error';
import { ShapedLayout } from '../../layouts/ShapedLayout/ShapedLayout';
import { useAuth0ExtractUrlError } from '../../hooks/useAuth0ExtractUrlError';
import { Auth0Info } from '../Auth0Info/Auth0Info';

export const Auth0TestPage = () => {
  const { userProfile, error: auth0Error } = useAuth0QueryProfile();
  const errorExtractedFromUrl = useAuth0ExtractUrlError();
  const error = errorExtractedFromUrl || auth0Error;

  return (
    <Fragment>
      {!error && (
        <ShapedLayout>
          <div class="bg-white opacity-80 absolute z-20 w-screen h-screen flex justify-center items-center">
            {!userProfile && <DidomiLoader />}
            {userProfile && <Auth0Info userProfile={userProfile} />}
          </div>
        </ShapedLayout>
      )}
      {error && <Error error={error} />}
    </Fragment>
  );
};
