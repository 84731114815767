import { h } from 'preact';
import {
  DidomiButton,
  DidomiLink,
  DidomiIllustration,
} from '@didomi/ui-atoms-react';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';
import { useAuthMethods } from '@didomi/utility-react';

const accountExistsMessages = {
  'samlp': {
    title: 'You already have an account!',
    description:
      'On the login page, fill in your e-mail address and click on "Continue" to be redirected to your organization\'s sign in page',
  },
  'auth0': {
    title: 'You already have an account!',
    description:
      'On the login page, fill in your e-mail address and click on "Continue" to enter your password',
  },
  'google-oauth2': {
    title: 'You already have an account!',
    description: 'On the login page, click on "Login with Google"',
  },
  'windowslive': {
    title: 'You already have an account!',
    description: 'On the login page, click on "Login with Microsoft Account"',
  },
};

export const RegisterUserExistsCard = ({
  onGoBack,
  activeIdentityProvider = null,
  federated = false,
}) => {
  const { logout } = useAuthMethods();
  const title =
    (activeIdentityProvider &&
      accountExistsMessages[activeIdentityProvider]?.title) ||
    'You are in luck!';
  const description =
    (activeIdentityProvider &&
      accountExistsMessages[activeIdentityProvider]?.description) ||
    'Your e-mail address already exists in our database.';

  return (
    <RegistrationCard>
      <div
        class="min-h-[320px] m-auto flex flex-col justify-center items-center"
        data-testid="error-container"
      >
        <DidomiIllustration name="content-loading-error" />
        <h1 class="font-serif font-bold text-[20px] text-primary-blue-6 mt-xxs">
          {title}
        </h1>
        <p class="body text-body-normal font-light text-primary-blue-6 m-xs">
          {description}
        </p>
        <DidomiButton
          fullWidth
          onClick={() =>
            logout({
              redirectUri: '/',
              federated,
            })
          }
        >
          Login
        </DidomiButton>
        <div class="font-sans text-link-small text-primary-blue-6 mt-s">
          <span>You don’t have a Didomi account yet?</span>
          <DidomiLink
            class="ml-xxs children:(text-body-small!)"
            text="Register"
            onClick={onGoBack}
          ></DidomiLink>
        </div>
      </div>
    </RegistrationCard>
  );
};
