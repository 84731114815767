import { useAuthMethods, useEnvironment } from '@didomi/utility-react';
import { useEffect, useRef, useState } from 'preact/hooks';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ObtainTokenError } from '../types/obtainTokenError';

declare interface useAuth0TokenOptions {
  onTokenParsed?: () => void;
  onTokenParseError?: (e: any) => void;
}

declare interface useAuth0TokenModel {
  isLoading: boolean;
}

export const useParseTokenFromHash = (
  hash: string,
  { onTokenParsed, onTokenParseError }: useAuth0TokenOptions = {},
): useAuth0TokenModel => {
  const { apiBaseUrl } = useEnvironment();
  const { parseSsoHash, checkSession, setToken } = useAuthMethods();
  const completedHash = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!hash || completedHash.current) return;

    /* istanbul ignore if */
    if (!parseSsoHash || !checkSession || !setToken) return;

    const errorClaim = `${apiBaseUrl}/error`;
    const subClaim = 'sub';

    parseSsoHash(hash, false)
      .catch(() => checkSession(false))
      .then((authResult) => {
        if (authResult === null) {
          throw new Error('authResult is null');
        }
        if (!authResult.accessToken) {
          throw new Error('authResult does not contain an accessToken');
        }

        const decodedToken: JwtPayload = jwt_decode(authResult.accessToken);
        const auth0ErrorInClaim = decodedToken[errorClaim];
        if (auth0ErrorInClaim) {
          throw new ObtainTokenError(auth0ErrorInClaim, decodedToken[subClaim]);
        }

        setToken(authResult.accessToken);
      })
      .then(() => {
        if (onTokenParsed) {
          onTokenParsed();
        }
        completedHash.current = true;
        setIsLoading(false);
      })
      .catch((e) => {
        if (onTokenParseError) {
          onTokenParseError(e);
        }
        completedHash.current = true;
        setIsLoading(false);
      });
  }, [
    parseSsoHash,
    checkSession,
    setToken,
    onTokenParsed,
    onTokenParseError,
    apiBaseUrl,
    hash,
  ]);

  return { isLoading };
};
