import { FunctionalComponent, h } from 'preact';
import {
  DidomiIllustration,
  DidomiButton,
  DidomiHintbox,
} from '@didomi/ui-atoms-react';
import { ErrorLayout } from '../../layouts/ErrorLayout/ErrorLayout';
import { UserProfile } from '@didomi/utility/dist/typings/src/user-profile/models/user-profile.model';

interface Auth0InfoProps {
  userProfile?: UserProfile;
}

export const Auth0Info: FunctionalComponent<Auth0InfoProps> = ({
  userProfile,
}) => {
  return (
    <ErrorLayout>
      <div
        class="min-h-[422px] flex flex-col justify-center items-center"
        data-testid="auth0info-container"
      >
        <DidomiIllustration name="success" />
        <h1 class="font-serif font-bold text-[20px] text-primary-blue-6 mt-xxs">
          It works!
        </h1>
        <p class="body text-body-normal font-light text-primary-blue-6 mb-s max-w-[600px] text-center leading-8">
          You have successfully configured your SSO connection and logged in
          with <b>{userProfile.email}</b>
        </p>

        <DidomiHintbox
          class="max-w-[600px] mb-s"
          titleText="Return to your SSO settings tab"
          headerIconName="speaker-half"
        >
          Click the button below to close the current tab and navigate back to
          SSO settings tab to finalize your SSO configuration
        </DidomiHintbox>

        <DidomiButton onClick={() => window.close()}>
          Continue SSO settings
        </DidomiButton>
      </div>
    </ErrorLayout>
  );
};
