import { FunctionalComponent, h } from 'preact';
import { DidomiLink, DidomiIllustration } from '@didomi/ui-atoms-react';
import { ErrorLayout } from '../../layouts/ErrorLayout/ErrorLayout';
import { Auth0Error } from '../../types';
import { useAuthMethods } from '@didomi/utility-react';
import { RegisterUserExistsCard } from '../../components/RegisterUserExistsCard/RegisterUserExistsCard';

interface ErrorProps {
  error?: Auth0Error;
  auth0Id?: string;
}

export const Error: FunctionalComponent<ErrorProps> = ({ error, auth0Id }) => {
  const { logout } = useAuthMethods();

  // This string must align with the error message in https://gitlab.com/didomi/authorization/auth0/-/blob/main/actions/post-login.js
  if (error?.error_description?.startsWith('User has active account |')) {
    try {
      const activeIdentityProvider = error.error_description.split('|')[1];
      return (
        <ErrorLayout>
          <div
            class="min-h-[422px] flex flex-col justify-center items-center"
            data-testid="error-container--user-exists"
          >
            <RegisterUserExistsCard
              activeIdentityProvider={activeIdentityProvider}
              federated={auth0Id && auth0Id.startsWith('samlp')}
              onGoBack={() => {
                logout({
                  federated: auth0Id && auth0Id.startsWith('samlp'),
                  redirectUri: `/auth/register`,
                });
              }}
            ></RegisterUserExistsCard>
          </div>
        </ErrorLayout>
      );
    } catch {}
  }

  return (
    <ErrorLayout>
      <div
        class="min-h-[422px] flex flex-col justify-center items-center"
        data-testid="error-container"
      >
        <DidomiIllustration name="content-loading-error" />
        <h1 class="font-serif font-bold text-[20px] text-primary-blue-6 mt-xxs">
          Sorry...
        </h1>
        <p class="body text-body-normal font-light text-primary-blue-6 mb-l  max-w-[600px] text-center leading-8">
          {error.error_description ||
            'Unauthorized or failed to retrieve user profile'}
        </p>
        <div class="font-sans text-link-small text-primary-blue-6 mt-xs">
          <span>Already a Didomi account?</span>
          <DidomiLink
            class="ml-xxs children:(text-body-small!)"
            text="Sign in"
            onClick={() =>
              logout({
                federated: auth0Id && auth0Id.startsWith('samlp'),
              })
            }
          ></DidomiLink>
        </div>
      </div>
    </ErrorLayout>
  );
};
