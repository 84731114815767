import { h } from 'preact';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';

export const RegisterCardLoading = () => {
  return (
    <RegistrationCard>
      <div
        class="reset-password-loading m-auto min-h-[422px] flex flex-col justify-center items-center"
        data-testid="loading-container"
      >
        <div class="h-16 flex justify-center items-center">
          <DidomiLoader></DidomiLoader>
        </div>
        <h1 class="font-serif font-bold text-[20px] text-primary-blue-6 mt-xxs">
          Please wait
        </h1>
        <p class="body text-body-normal font-light text-primary-blue-6 mb-l">
          We are creating your account
        </p>
      </div>
    </RegistrationCard>
  );
};
