import { h, Fragment } from 'preact';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { useAuth0PostLogin } from '../../hooks/useAuth0PostLogin';
import { useSPARouter } from '@didomi/utility-react';
import { Error } from '../Error/Error';
import { BlankLayout } from '../../layouts/BlankLayout/BlankLayout';
import { useAuth0ExtractUrlError } from '../../hooks/useAuth0ExtractUrlError';

/**
 * This is the common redirect for all flows. We have to obtain a token and redirect to the required page
 */

const auth0PrefixRouteMap = {
  'auth0': '/',
  'google-oauth2': '/',
  'windowslive': '/',
  'samlp': '/',
};

export const Auth0Callback = () => {
  const { isLoading, error: auth0Error, auth0Id } = useAuth0PostLogin();
  const { navigateToAbsolute } = useSPARouter();
  const errorExtractedFromUrl = useAuth0ExtractUrlError();
  const error = errorExtractedFromUrl || auth0Error;

  // TODO Change it when accounts will be linked
  const prefix = auth0Id?.split('|')[0];
  const prefixRoute = auth0PrefixRouteMap[prefix];

  if (!isLoading && !error && prefixRoute) {
    navigateToAbsolute(prefixRoute);
    return;
  }

  return (
    <Fragment>
      {isLoading && (
        <BlankLayout>
          <div
            class="bg-white opacity-80 absolute z-20 w-screen h-screen flex justify-center items-center"
            data-testid="page-loading"
          >
            <DidomiLoader />
          </div>
        </BlankLayout>
      )}
      {error && <Error error={error} auth0Id={auth0Id} />}
    </Fragment>
  );
};
