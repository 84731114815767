import { useAuthMethods, useUserProfileMethods } from '@didomi/utility-react';
import { useState } from 'preact/hooks';
import { useParseTokenFromHash } from './useParseTokenFromHash.hook';
import { Auth0Error } from '../types';
import { ObtainTokenError } from '../types/obtainTokenError';
import { useHash } from './useHash.hook';
import { useIsMounted } from '@didomi/helpers-react';

export const useAuth0PostLogin = () => {
  const { postLoginProcess } = useAuthMethods();
  const { getActiveUserProfile } = useUserProfileMethods();
  const [error, setError] = useState<Auth0Error | null>(null);
  const [auth0Id, setAuth0Id] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const isMounted = useIsMounted();

  const [hash] = useHash();

  const { isLoading: isLoadingTokenParse } = useParseTokenFromHash(hash, {
    onTokenParsed: async () => {
      await postLoginProcess();
      if (isMounted()) {
        setAuth0Id(getActiveUserProfile()?.auth0_id);
        setIsLoading(false);
      }
    },
    onTokenParseError: (e) => {
      if (isMounted()) {
        if (e instanceof Error || e instanceof ObtainTokenError) {
          setError({
            error: e.name,
            error_description: e.message,
          });

          if (e instanceof ObtainTokenError) {
            setAuth0Id(e.sub);
          }
        } else {
          setError(e);
        }
        setIsLoading(false);
      }
    },
  });

  return { isLoading: isLoading || isLoadingTokenParse, auth0Id, error };
};
