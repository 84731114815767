import { useUtility } from '@didomi/utility-react';
import { useEffect, useRef, useState } from 'preact/hooks';
import { UserProfile } from '@didomi/utility/dist/typings/src/user-profile/models/user-profile.model';

export const useAuth0QueryProfile = () => {
  const componentIsMounted = useRef(true);
  const { Auth0 } = useUtility();
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  // auth0 returns access information in the hash
  // so that instead of parsing it, we use URLSearchParams to get its content
  const hashLocation = window.location.hash.replace('#', '?');
  const urlParams = new URLSearchParams(hashLocation);
  const accessToken = urlParams.get('access_token');

  useEffect(() => {
    /* istanbul ignore if */
    if (!Auth0) return;

    // The token are parsed and validated (only the Id Token, not the Access Token)
    // and saved to indicate a succesful login
    Auth0.getProfileByToken(accessToken)
      .then((profile) => setUserProfile(profile))
      .catch((e) => {
        if (componentIsMounted) {
          setError(e);
        }
      });

    return () => {
      componentIsMounted.current = false;
    };
  }, [accessToken, Auth0]);

  return { userProfile, error };
};
