import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { RegistrationInput } from '../types';

/**
 * This hook is used by the self-service registration form on /auth/register
 * When the user fills out the form, this hook will create the user in the Admin API
 */
export const useRegisterUser = (
  config?: UseMutationOptions<AxiosResponse, AxiosError, RegistrationInput>,
): UseMutationResult<AxiosResponse, AxiosError, RegistrationInput> => {
  const mutation = useMutation(
    async (userData: RegistrationInput) =>
      await axios.post(`${CONFIG.restServerUri}/users`, {
        ...userData,
        email: userData.email?.toLocaleLowerCase().trim(),
        self_register: true,
      }),
    config,
  );
  return mutation;
};
