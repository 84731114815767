import { useState, useCallback, useEffect } from 'react';

export const useLocationSearch = () => {
  const [search, setSearch] = useState(() => window.location.search);

  const searchChangeHandler = useCallback(() => {
    setSearch(window.location.search);
  }, []);

  /* istanbul ignore next until we find a way to trigger the event */
  useEffect(() => {
    if (!searchChangeHandler) return;

    window.addEventListener('popstate', searchChangeHandler);
    return () => {
      window.removeEventListener('popstate', searchChangeHandler);
    };
  }, [searchChangeHandler]);

  return search;
};
