import { FunctionalComponent } from 'preact';
import { useUtility } from '@didomi/utility-react';

type RedirectProps = {
  default?: boolean;
};

export const RedirectToAuth0: FunctionalComponent<RedirectProps> = () => {
  const { Auth0 } = useUtility();
  // session exists -> we will be logged in automatically by auth0 (no actions required)
  // session does not exist -> login will be displayed
  Auth0.redirectToUniversalLogin(window.location.origin);

  return null;
};
