import { h, FunctionComponent } from 'preact';
import { useMediaQuery } from '@didomi/helpers-react';

export const RegistrationCard: FunctionComponent = ({ children }) => {
  const isExtraSmall = useMediaQuery('(max-width: 428px)');

  if (isExtraSmall) {
    return (
      <div class="w-full p-m h-full bg-white flex items-center justify-center text-center children:h-auto!">
        {children}
      </div>
    );
  }

  return (
    <div class="lg:min-h-screen! w-full p-xl bg-white text-center flex xl:(children:(max-w-[615px]))">
      {children}
    </div>
  );
};
