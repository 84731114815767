import { Fragment, h } from 'preact';
import { Formik, Form } from 'formik';
import {
  DidomiPartnerButton,
  DidomiLink,
  DidomiSwitchField,
} from '@didomi/ui-atoms-react';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';
import { useAuthMethods } from '@didomi/utility-react';
import * as Yup from 'yup';

const TERMS_OF_USE_VERSION = 1;
const PRIVACY_POLICY_VERSION = 1;

const RegistrationPartnerFormSchema = Yup.object().shape({
  termsOfUsePartner: Yup.boolean()
    .oneOf([true], 'Required')
    .required('Required'),
  privacyPolicyPartner: Yup.boolean()
    .oneOf([true], 'Required')
    .required('Required'),
});

export const RegisterWithPartnerCard = ({ onGoBack, partner }) => {
  const { socialSsoLogin } = useAuthMethods();

  const handleLogInWithGoogleClick = () => {
    socialSsoLogin('google-oauth2', {
      terms_version: TERMS_OF_USE_VERSION,
      privacy_policy_version: PRIVACY_POLICY_VERSION,
    });
  };

  const handleLogInWithMicrosoftClick = () => {
    socialSsoLogin('windowslive', {
      terms_version: TERMS_OF_USE_VERSION,
      privacy_policy_version: PRIVACY_POLICY_VERSION,
    });
  };

  const partnerSubmitHandlers = {
    google: handleLogInWithGoogleClick,
    microsoft: handleLogInWithMicrosoftClick,
  };

  return (
    <RegistrationCard>
      <div class="flex flex-col justify-center items-center w-full h-full">
        <Formik
          initialValues={{
            termsOfUsePartner: false,
            privacyPolicyPartner: false,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={RegistrationPartnerFormSchema}
          onSubmit={partnerSubmitHandlers[partner]}
        >
          <Form class="flex flex-col w-full">
            <Fragment>
              <h1 class="font-serif text-[20px] leading-[25px] font-bold text-primary-blue-6 mb-xs">
                Start with a<br />
                compliance check today!
              </h1>

              <DidomiSwitchField
                class="w-full mt-s"
                id="terms-of-use-switch-partner"
                name="termsOfUsePartner"
                valueSelected={true}
                valueNotSelected={false}
                data-testId="register-tos-partner-input"
                data-cy="register-tos-partner-input"
                hideErrorMessage
              >
                <div class="text-left text-body-small">
                  <span>I accept the </span>
                  <DidomiLink
                    class="children:(text-body-small! children:(text-primary-blue-6!))"
                    text="terms of use"
                    target="_blank"
                    href={`https://tos.console.didomi.io/v${TERMS_OF_USE_VERSION}`}
                  ></DidomiLink>
                  <span> *</span>
                </div>
              </DidomiSwitchField>
              <DidomiSwitchField
                class="w-full mt-xxxs"
                id="privacy-policy-switch-partner"
                name="privacyPolicyPartner"
                valueSelected={true}
                valueNotSelected={false}
                data-testId="register-privacy-policy-partner-input"
                data-cy="register-privacy-policy-partner-input"
                hideErrorMessage
              >
                <div class="text-left text-body-small">
                  <span>I accept </span>
                  <DidomiLink
                    class="children:(text-body-small! children:(text-primary-blue-6!))"
                    text="Didomi's privacy policy"
                    target="_blank"
                    href={`https://privacy.console.didomi.io/v${PRIVACY_POLICY_VERSION}`}
                  ></DidomiLink>
                  <span> *</span>
                </div>
              </DidomiSwitchField>
              <div class="mt-xxs font-sans text-primary-blue-6 italic text-[12px] text-left">
                * Required fields
              </div>
              <div class="mb-s mt-m">
                {partner === 'google' && (
                  <DidomiPartnerButton
                    id="partner-google"
                    data-testid="register-with-google-action"
                    data-tracking="self-service-create-my-account-google"
                    partner="google"
                    type="submit"
                  >
                    Register with Google
                  </DidomiPartnerButton>
                )}
                {partner === 'microsoft' && (
                  <DidomiPartnerButton
                    id="partner-microsoft"
                    data-testid="register-with-microsoft-action"
                    data-tracking="self-service-create-my-account-microsoft"
                    partner="microsoft"
                    type="submit"
                  >
                    Register with Microsoft
                  </DidomiPartnerButton>
                )}
              </div>
              <div class="font-sans text-link-small text-primary-blue-6 mt-xxxs">
                <span>Already a Didomi account?</span>
                <DidomiLink
                  class="ml-xxs children:(text-body-small!)"
                  text="Sign in"
                  href="/"
                ></DidomiLink>
              </div>
              <div class="font-sans text-link-small text-primary-blue-6 mt-xxxs">
                <span>I prefer to</span>
                <DidomiLink
                  class="ml-xxxs children:(text-body-small!)"
                  text="signup with email"
                  onClick={onGoBack}
                ></DidomiLink>
              </div>
            </Fragment>
          </Form>
        </Formik>
      </div>
    </RegistrationCard>
  );
};
