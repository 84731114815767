import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Formik, Form } from 'formik';
import {
  DidomiButton,
  DidomiPartnerButton,
  DidomiLink,
  DidomiPasswordInputField,
  DidomiTextInputField,
  DidomiSwitchField,
} from '@didomi/ui-atoms-react';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';
import { RegistrationFormInput } from '../../types';
import { getNameFromEmail } from '../../utils';
import * as Yup from 'yup';

const TERMS_OF_USE_VERSION = 1;
const PRIVACY_POLICY_VERSION = 1;

/**
 * Validates that the password matches at least 3 of the 4 rules
 * Lower case letters (a-z)
 * Upper case letters (A-Z)
 * Numbers (i.e. 0-9)
 * Special characters (e.g. !@#$%^&*)
 * @param {string | undefined} password The password to test
 * @returns {boolean} True if it meets the criteria
 */
const validatePassword = (p: string | undefined): boolean => {
  if (!p) return false;

  /* OWASP Special Characters: https://www.owasp.org/index.php/Password_special_characters */
  /* Taken from https://github.com/auth0/password-sheriff/blob/master/lib/rules/contains.js#L4 */
  const specialCharacters = [
    ' ',
    '!',
    '"',
    '#',
    '\\$',
    '%',
    '&',
    "'",
    '\\(',
    '\\)',
    '\\*',
    '\\+',
    ',',
    '-',
    '\\.',
    '/',
    ':',
    ';',
    '<',
    '=',
    '>',
    '\\?',
    '@',
    '\\[',
    '\\\\',
    '\\]',
    '\\^',
    '_',
    '`',
    '{',
    '\\|',
    '}',
    '~',
  ].join('|');
  const specialCharactersRegexp = new RegExp(specialCharacters);

  return (
    [
      /\d/, // digits
      /[a-z]/, // lower
      /[A-Z]/, // upper
      specialCharactersRegexp,
    ].reduce((acc, reg) => (reg.test(p) ? ++acc : acc), 0) >= 3
  );
};

const RegistrationFormSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('The format of your email address is not correct')
    .required('Your email address is required'),
  password: Yup.string()
    .nullable()
    .min(8, 'Please improve your password strength')
    .required('A password is required')
    .test('valid-password', 'Please improve your password strength', (p) =>
      validatePassword(p),
    ),
  termsOfUse: Yup.boolean().oneOf([true], 'Required').required('Required'),
  privacyPolicy: Yup.boolean().oneOf([true], 'Required').required('Required'),
});

export const RegisterCardEmailPassword = ({
  onRegisterWithSocialSSO,
  onRegistrationStart,
}) => {
  const [registrationForm, setRegistrationForm] =
    useState<RegistrationFormInput>({
      email: '',
      password: '',
      privacyPolicy: false,
      termsOfUse: false,
    });

  const handleFormSubmit = async ({
    email,
    password,
    privacyPolicy,
    termsOfUse,
  }) => {
    setRegistrationForm((r) => ({
      email,
      password,
      privacyPolicy,
      termsOfUse,
    }));
    onRegistrationStart({
      email,
      password,
      name: getNameFromEmail(email),
      terms_version: TERMS_OF_USE_VERSION,
      privacy_policy_version: PRIVACY_POLICY_VERSION,
    });
  };

  return (
    <RegistrationCard>
      <Formik
        initialValues={registrationForm}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={RegistrationFormSchema}
        onSubmit={handleFormSubmit}
      >
        <Form class="flex flex-col w-full m-auto">
          <h1 class="font-serif text-[20px] leading-[25px] font-bold text-primary-blue-6 mb-xs">
            Start with a<br />
            compliance check today!
          </h1>

          <div class="flex flex-col gap-xxs xl:(flex-row! gap-xs!) mb-xs">
            <DidomiPartnerButton
              partner="google"
              type="button"
              data-testid="register-with-google"
              onClick={() => onRegisterWithSocialSSO('google')}
            >
              Register with Google
            </DidomiPartnerButton>
            <DidomiPartnerButton
              partner="microsoft"
              type="button"
              data-testid="register-with-microsoft"
              onClick={() => onRegisterWithSocialSSO('microsoft')}
            >
              Register with Microsoft
            </DidomiPartnerButton>
          </div>
          <span class="h5 text-primary-blue-5 text-[12px] leading-[16px] mb-xxs">
            Or
          </span>

          <DidomiTextInputField
            label="Professional email *"
            name="email"
            placeholder="Please enter your professional email address"
            autofocus
            autocomplete="on"
            data-cy="register-email-input"
          ></DidomiTextInputField>

          <DidomiPasswordInputField
            label="Create password *"
            name="password"
            autocomplete="on"
            placeholder="Please create a password"
            showPasswordMeter={true}
            passwordMeterType="floating"
            data-cy="register-password-input"
          ></DidomiPasswordInputField>

          <DidomiSwitchField
            class="w-full"
            id="terms-of-use-switch"
            name="termsOfUse"
            valueSelected={true}
            valueNotSelected={false}
            data-testId="register-tos-input"
            data-cy="register-tos-input"
            hideErrorMessage
          >
            <div class="text-left text-body-small">
              <span>I accept the </span>
              <DidomiLink
                class="children:(children:(text-body-small! text-primary-blue-6!))"
                text="terms of use"
                data-tracking="self-service-terms-registration-form"
                target="_blank"
                href={`https://tos.console.didomi.io/v${TERMS_OF_USE_VERSION}`}
              ></DidomiLink>
              <span> *</span>
            </div>
          </DidomiSwitchField>
          <DidomiSwitchField
            class="w-full mt-xxxs"
            id="privacy-policy-switch"
            name="privacyPolicy"
            valueSelected={true}
            valueNotSelected={false}
            data-testId="register-privacy-policy-input"
            data-cy="register-privacy-policy-input"
            hideErrorMessage
          >
            <div class="text-left text-body-small">
              <span>I accept </span>
              <DidomiLink
                class="children:(children:(text-body-small! text-primary-blue-6!))"
                data-tracking="self-service-privacy-policy-registration-form"
                text="Didomi's privacy policy"
                target="_blank"
                href={`https://privacy.console.didomi.io/v${PRIVACY_POLICY_VERSION}`}
              ></DidomiLink>
              <span> *</span>
            </div>
          </DidomiSwitchField>
          <div class="mt-xxs font-sans text-primary-blue-6 italic text-[12px] text-left">
            * Required fields
          </div>
          <DidomiButton
            data-tracking="self-service-create-my-account"
            class="mt-xs"
            fullWidth
            type="submit"
          >
            Create my account
          </DidomiButton>
          <div class="font-sans text-link-small text-primary-blue-6 mt-xs">
            <div class="text-tooltip mb-s text-justify">
              Didomi collects your personal data for the purpose of providing
              you with the required product or service on the legal basis of the
              execution of the contract between you and Didomi. Didomi also
              processes your personal data for commercial prospecting purposes,
              to send you communications about our products and services, our
              newsletter and other marketing contents that you may be interested
              in. This processing is based on our legitimate interest of
              commercial development. You may opt out of receiving commercial
              communications from us at any time by clicking on the unsubscribe
              link at the bottom of any of our emails or by visiting our{' '}
              <DidomiLink
                text="Preference Center"
                class="children:(children:(text-tooltip! leading-tooltip!))"
                target="_blank"
                rel="noopener noreferrer"
                href="https://preferences.didomi.io/en/?__hstc=47810744.d5cf197267cfd5bc61215a8634161d0b.1652188454993.1652966312339.1652971901095.19&__hssc=47810744.2.1652971901095&__hsfp=4285294155&hsutk=d5cf197267cfd5bc61215a8634161d0b&contentType=standard-page&pageId=38081350101"
              ></DidomiLink>
              . Furthermore you have the right to access, delete, rectify and
              object to the processing of your personal data by visiting our{' '}
              <DidomiLink
                text="Privacy center"
                class="children:(children:(text-tooltip! leading-tooltip!))"
                target="_blank"
                rel="noopener noreferrer"
                href="https://privacy.didomi.io/fr/"
              ></DidomiLink>{' '}
              or by contacting our DPO to the following email address:{' '}
              <DidomiLink
                text="dpo@didomi.io"
                target="_blank"
                rel="noopener noreferrer"
                class="children:(children:(text-tooltip! leading-tooltip!))"
                href="mailto:dpo@didomi.io"
              ></DidomiLink>
              .
            </div>
            <span>Already have an account? </span>
            <DidomiLink
              class="children:(text-body-small!)"
              target="_blank"
              rel="noopener noreferrer"
              text="Sign in"
              href="/"
            ></DidomiLink>
            <span>.</span>
          </div>
        </Form>
      </Formik>
    </RegistrationCard>
  );
};
