import { h, FunctionalComponent } from 'preact';

/**
 * Blank Layout
 * Just a white background
 */
const BlankLayout: FunctionalComponent = ({ children }) => (
  <section
    class="bg-white w-full h-full min-h-[800px] flex items-center justify-center"
    data-testid="page-wrapper"
  >
    {children}
  </section>
);

export { BlankLayout };
