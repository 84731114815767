import { h } from 'preact';
import {
  DidomiIllustration,
  DidomiButton,
  DidomiLink,
} from '@didomi/ui-atoms-react';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';

export const RegisterCardError = ({ onRetry }) => {
  return (
    <RegistrationCard>
      <div
        class="min-h-[422px] m-auto flex flex-col justify-center items-center"
        data-testid="error-container"
      >
        <DidomiIllustration name="content-loading-error" />
        <h1 class="font-serif font-bold text-[20px] text-primary-blue-6 mt-xxs">
          Sorry...
        </h1>
        <p class="body text-body-normal font-light text-primary-blue-6 mb-l">
          We are experiencing some difficulties in creating your account. You
          can retry by clicking on the button below
        </p>
        <DidomiButton id="retry-button" fullWidth onClick={onRetry}>
          Retry
        </DidomiButton>
        <div class="font-sans text-link-small text-primary-blue-6 mt-xs">
          <span>Already a Didomi account?</span>
          <DidomiLink
            class="ml-xxs children:(text-body-small!)"
            text="Sign in"
            href="/"
          ></DidomiLink>
        </div>
      </div>
    </RegistrationCard>
  );
};
