/**
 * Extract formatted names from any email.
 *
 * @param {String} email Any string is excepted, but only strings containing a @ will be evaluated.
 * @return {String} Returns the extracted name, if no can be extracted an empty string is returned.
 */
export const getNameFromEmail = (email: string): string => {
  'use strict';

  const getNamePart = (email: string): string => {
    const parts = email.split('@');
    return parts[0];
  };

  const removeEndingPlus = (email: string): string => {
    const parts = email.split('+');
    return parts[0];
  };

  const removeSpecials = (email: string) => {
    const lower = email.toLowerCase();
    const upper = email.toUpperCase();

    let res = '';
    for (let i = 0; i < lower.length; ++i) {
      if (lower[i] != upper[i] || lower[i].trim() === '') {
        res += email[i];
      } else {
        res += ' ';
      }
    }
    return res;
  };

  const capitaliseFirstLetter = (email: string): string => {
    const arr = email.split(' ');
    let res = '';
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] != ' ') {
        res +=
          arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase() + ' ';
      }
    }
    return res.trim();
  };

  const removeDoubleSpaces = (email: string): string => {
    return email.replace(/ +(?= )/g, '');
  };

  let name = email;
  name = getNamePart(name);
  name = removeEndingPlus(name);
  name = removeSpecials(name);
  name = removeDoubleSpaces(name);
  name = capitaliseFirstLetter(name);
  return name;
};
