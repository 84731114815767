import { h, FunctionalComponent } from 'preact';
import { DidomiIllustration } from '@didomi/ui-atoms-react';

/**
 * Shaped Layout
 * Original login screen layout, with pink background and floating shapes
 */
const ShapedLayout: FunctionalComponent = ({ children }) => (
  <section
    class="bg-pink-gradient w-full h-full min-h-[800px] flex items-center justify-center"
    data-testid="page-wrapper"
  >
    <div class="auth-card-wrapper relative flex items-center justify-center h-full">
      <DidomiIllustration
        name="shape-d"
        width="334"
        height="332"
        class="shape-d fixed"
        data-testid="illustration-shape-d"
        style="top: 8%; right: 10%"
      />
      <DidomiIllustration
        name="shape-triangle"
        class="shape-triangle fixed"
        data-testid="illustration-shape-triangle"
        style="bottom: 8%; left: 10%"
      />
    </div>
    {children}
  </section>
);

export { ShapedLayout };
