import { h, FunctionalComponent } from 'preact';
import BlueBlur from '/assets/images/icons/Blue-blur.png';
import { useEnvironment } from '@didomi/utility-react';

/**
 * Error Layout
 * Applies only to display an error from auth0.
 */
const ErrorLayout: FunctionalComponent = ({ children }) => {
  const { assetsBaseUrl } = useEnvironment();

  return (
    <section
      class="w-full h-full relative justify-center min-h-[800px]"
      data-testid="page-wrapper"
    >
      <div class="absolute w-full">
        <img
          alt="didomi-logo"
          class="relative z-10 m-auto mt-14"
          src={assetsBaseUrl + '/Logo_Didomi_Full_Blue.svg'}
        />
      </div>
      <div class="w-full h-full flex justify-center relative px-l overflow-hidden">
        <div class="absolute left-0 h-full bg-white w-[30vw]" />

        <div class="w-full max-w-[1200px] z-10 flex justify-center items-center">
          <div class="flex-1 justify-items-center place-items-center">
            {children}
          </div>
        </div>
        <img
          src={BlueBlur}
          alt=""
          class="absolute -left-[180px] -bottom-[60px]"
        />
      </div>
    </section>
  );
};

export { ErrorLayout };
